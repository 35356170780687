@use "~@angular/material" as mat;
@include mat.core();
@import "~ngx-toastr/toastr";
@import "~bootstrap";
//swiper styles
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
//swimlane styles
@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/material.scss";
@import "~@swimlane/ngx-datatable/assets/icons.css";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import url(./assets/styles/main.styles.scss);
$md-mcgpalette0: ( 50: #e3e3ed, 100: #b9bad2, 200: #8b8cb5, 300: #5d5d97, 400: #3a3b80, 500: #232459, 600: #141562, 700: #111157, 800: #0d0e4d, 900: #07083c, A100: #7474ff, A200: #4141ff, A400: #0e0eff, A700: #0000f3, contrast: (50: #000000, 100: #000000, 200: #000000, 300: #ffffff, 400: #ffffff, 500: #ffffff, 600: #ffffff, 700: #ffffff, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #ffffff, A400: #ffffff, A700: #ffffff, ), );
$md-mcgpalette1: ( 50: #eeeefa, 100: #d4d4f2, 200: #b8b7e9, 300: #9b9ae0, 400: #8585da, 500: #706fd3, 600: #6867ce, 700: #5d5cc8, 800: #5352c2, 900: #4140b7, A100: #ffffff, A200: #232459, A400: #a4a3ff, A700: #8b8aff, contrast: (50: #000000, 100: #000000, 200: #000000, 300: #000000, 400: #000000, 500: #ffffff, 600: #ffffff, 700: #ffffff, 800: #ffffff, 900: #ffffff, A100: #000000, A200: #000000, A400: #000000, A700: #000000, ), );
$kairhos-front-end-primary: mat.define-palette($md-mcgpalette0);
$kairhos-front-end-accent: mat.define-palette($md-mcgpalette1, A200, A100, A400);
// The warn palette is optional (defaults to red).
$kairhos-front-end-warn: mat.define-palette(mat.$red-palette);
$kairhos-front-end-theme: mat.define-light-theme((color: (primary: $kairhos-front-end-primary, accent: $kairhos-front-end-accent, warn: $kairhos-front-end-warn, ), ));
@font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: normal;
    src: url("./assets/font/GoogleSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    src: url("./assets/font/GoogleSans-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: bold;
    src: url("./assets/font/GoogleSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Google Sans";
    font-style: italic;
    font-weight: normal;
    src: url("./assets/font/GoogleSans-Italic.ttf") format("truetype");
}

@font-face {
    font-family: "Google Sans";
    font-style: italic;
    font-weight: 500;
    src: url("./assets/font/GoogleSans-MediumItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Google Sans";
    font-style: italic;
    font-weight: bold;
    src: url("./assets/font/GoogleSans-BoldItalic.ttf") format("truetype");
}

*:not(mat-icon) {
    font-family: "Google Sans";
}

@include mat.all-component-themes($kairhos-front-end-theme);
html {
    /*  font-size: calc((100vw / 1920) * 16); */
    body {
        height: 100%;
        width: 100%;
        margin: 0;
        font-size: 1rem;
        height: 100%;
        letter-spacing: 0px;
        background-color: #f0f2f5;
        overflow: auto;
        .primary {
            color: #232459 !important;
        }
        .bg-primary {
            background-color: #232459 !important;
        }
        .mat-button {
            font-weight: normal !important;
            border-radius: 10px !important;
        }
        datatable-header {
            background-color: #f4f4f4 !important;
        }
        .datatable-row-center {
            border-bottom: 1px solid #f4f4f4;
        }
        .datatable-row-wrapper:hover .datatable-row-center {
            border-color: #232459;
        }
        .mat-dialog-container {
            padding: 0px !important;
            border-radius: 1.3rem !important;
            height: auto;
        }
        .mat-form-field-themewrapper {
            padding-bottom: 0px !important;
            border-radius: 1.3rem !important;
        }
        .cursor-pointer {
            cursor: pointer;
        }
        .secondary {
            color: #706fd3 !important;
        }
        .bg-purple {
            background-color: #706fd3 !important;
        }
        .view-screen {
            .modal-content {
                border: none !important;
            }
        }
        .muted {
            color: #e1e1e8 !important;
        }
        .mat-form-field-outline-start {
            border-radius: 13px 0 0 13px !important;
            min-width: 10px !important;
        }
        // html body .mat-form-field-outline-start {
        //   border-radius: 0.5rem 0 0 0.5rem !important;
        // }
        // html body .mat-form-field-outline-end {
        //   border-radius: 0 0.5rem 0.5rem 0 !important;
        // }
        .mat-form-field-outline-end {
            border-radius: 0 13px 13px 0 !important;
        }
        .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
            border-color: #706fd3;
        }
        .mat-radio-button.mat-accent .mat-radio-inner-circle {
            color: #706fd3;
            background-color: #706fd3;
        }
        h2 {
            font-size: 28px;
        }
        .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
            color: #1a85d6;
        }
        .mat-form-field-label .mat-focused {
            color: #0769ce;
        }
        .mat-form-field-appearance-outline.mat-focused .mat-form-field-label {
            color: #0769ce;
        }
        .mat-form-field.mat-form-field-invalid .mat-form-field-label {
            color: #f44336 !important;
        }
        .mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
            color: none !important;
        }
        .lightSecondary {
            background-color: #f1f1fb !important;
        }
        .custom-bg {
            background-color: #eef3f9 !important;
        }
        .bg-grey {
            background-color: #f0f2f5 !important;
        }
        .bg-table {
            background-color: #f4f4f4 !important;
            font-weight: bold !important;
            letter-spacing: 2px;
            font-weight: bold;
            color: #7c848c;
        }
        .rounded {
            border-radius: 0.5rem !important;
        }
        .fs-small {
            font-size: 0.8rem !important;
        }
        .heading {
            color: #cccfd3;
            font-weight: bolder;
            letter-spacing: 3px;
        }
        .bg-info {
            background-color: #1a85d6 !important;
            color: white;
        }
        .example-large-box {
            width: 1281px;
            margin-top: 35px;
        }
        .example-section {
            margin: 12px 0;
        }
        .border-primary {
            color: #706fd3 !important;
            border-color: #706fd3 !important;
        }
        .border-info {
            color: #1a85d6 !important;
            border-color: #1a85d6 !important;
        }
        .border-blue {
            color: #232459 !important;
            border-color: #232459 !important;
        }
        .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
        .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after,
        [dir="rtl"] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after,
        [dir="rtl"] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before {
            width: 0 !important;
        }
        .bg-info {
            background-color: #1a85d6 !important;
            color: white;
        }
        .example-large-box {
            width: 1281px;
            margin-top: 35px;
        }
        .example-section {
            margin: 12px 0;
        }
        .example-margin {
            margin: 0 12px;
        }
        .shadow {
            box-shadow: 0 0 0.8rem rgb(0 0 0 / 15%) !important;
        }
        .search-bar {
            .search {
                padding: 0rem !important;
                margin: 0 !important;
                .mat-form-field-appearance-outline .mat-form-field-prefix {
                    top: 0.25rem !important;
                }
                .mat-form-field-appearance-outline .mat-form-field-prefix {
                    padding: 0 !important;
                }
                .mat-form-field-appearance-outline .mat-form-field-outline-start,
                .mat-form-field-appearance-outline .mat-form-field-outline-end {
                    border: 1px solid currentColor;
                    min-width: 5px;
                    border-radius: 0.4rem !important;
                }
            }
            .mat-form-field-appearance-outline .mat-form-field-infix {
                padding: 2.9px 0.9px 11.5px 27.3px !important;
            }
            .mat-form-field-infix {
                padding: 4.7px !important;
                border-top: 0.94375rem solid transparent;
            }
            .mat-form-field {
                font-size: inherit;
                font-weight: 400;
                line-height: 0.825;
                font-family: Roboto, "Helvetica Neue", sans-serif;
                letter-spacing: normal;
            }
            .mat-form-field-appearance-outline .mat-form-field-infix {
                padding: 0.3rem 0rem 1rem 1.4rem !important;
                top: -0.1rem;
            }
            .mat-form-field-appearance-outline .mat-form-field-outline {
                // color: #aaa8a8;
            }
            .mat-form-field-prefix .mat-icon,
            .mat-form-field-suffix .mat-icon {
                font-size: 150%;
                line-height: 0.725;
            }
            .mat-form-field-appearance-outline .mat-form-field-flex {
                min-height: 2.1rem;
                padding: 0rem 1.1rem 0rem 1.1rem;
            }
            input.mat-input-element {
                margin-top: 0.3rem !important;
                font-size: 0.9rem !important;
            }
        }
        .mat-form-field-appearance-outline .mat-form-field-outline {
            //color: #cccccc !important;
            background: white;
            border-radius: 0.8rem;
        }
        .ngx.pagination {
            a {
                font-size: 0.8rem;
            }
        }
        .ngx-pagination .current {
            padding: 0.4rem 0.7rem 0.2rem 0.7rem;
            background: #0769ce !important;
            color: #fefefe;
            cursor: default;
            border-radius: 0.3rem !important;
            font-size: 0.8rem !important;
        }
        .pagination-next {
            margin-left: 1.0625rem;
            background: #f6f6f6 !important;
            color: black !important;
            padding: 0.2rem 0.5rem 0.3rem 0rem !important;
        }
        .pagination-previous {
            margin-right: 1.1625rem;
            background: #f6f6f6 !important;
            color: black !important;
            padding: 0.2rem 0.1rem 0.3rem 0.5rem !important;
        }
        .mat-expansion-panel-body {
            padding: 0 0px 0px !important;
        }
        /* .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.6rem 0rem 1rem 0rem !important;
}
 */
        .section-2 {
            .mat-expansion-panel-body {
                border: 1px solid #e1e1e8 !important;
                background: #ffffff !important;
            }
        }
        .mat-expansion-panel-body {
            border: 1px solid #d1cfcf !important;
        }
        .overlay {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 10%);
            z-index: 4;
        }
        .right-modal {
            position: absolute;
            top: 0;
            right: 0;
            /* max-width: 50%;
          width: 100%; */
            height: 100vh;
            max-height: 100vh;
            padding: 1rem;
            background-color: $white;
            overflow: auto;
        }
        .datepicker {
            .mat-datepicker-toggle-default-icon,
            .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button .mat-datepicker-toggle-default-icon {
                margin: auto;
                color: #706fd3;
            }
            .mat-form-field-appearance-outline .mat-form-field-flex {
                padding: 0rem 0rem 0.175rem 0rem;
                margin-top: 0;
                position: relative;
            }
            .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button,
            .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-size: inherit;
                width: 1.3em;
                height: 1.2em;
            }
            input.mat-input-element {
                margin: 0rem 0rem 0.1rem 0.3rem !important;
                margin-top: 0.4375em !important;
                border: 1px solid #dfdfdf;
            }
            .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-datepicker-toggle-default-icon,
            .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
                display: block;
                width: 1.4em !important;
                height: 1.5em !important;
                margin: 0rem 0rem !important;
                padding: 0rem 0rem;
            }
        }
        .datepicker-popup {
            .mat-datepicker-toggle-default-icon,
            .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button .mat-datepicker-toggle-default-icon {
                margin: auto;
                color: #706fd3;
            }
            .mat-form-field-appearance-outline .mat-form-field-flex {
                padding: 0rem 0rem 0.175rem 0rem;
                margin-top: 0;
                position: relative;
            }
            .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button,
            .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-size: inherit;
                width: 1.3em;
                height: 1.2em;
            }
            input.mat-input-element {
                margin: 0rem 0rem 0.1rem 0.3rem;
                margin-top: 0.4375em;
                border: 1px solid #d6d6e0;
            }
            .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-datepicker-toggle-default-icon,
            .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
                display: block;
                width: 1.4em !important;
                height: 1.5em !important;
                margin: 0rem 0rem !important;
                padding: 0rem 0rem;
            }
        }
        .titlecase-1 {
            background: #fae9ee !important;
            color: #e04164 !important;
        }
        .titlecase-2 {
            background: #e8f3fb !important;
            color: #4589d8 !important;
        }
        .titlecase-3 {
            background: #e6f8f4 !important;
            color: #5fc4a9 !important;
        }
        .titlecase-4 {
            background: #fdf7ec !important;
            color: #f2bd66 !important;
        }
        .titlecase-5 {
            background: #003770 !important;
            color: #c9dbee !important;
        }
        .titlecase-6 {
            background: #f6e9f0 !important;
            color: #b84883 !important;
        }
        .titlecase-7 {
            background: #fdf7ec !important;
            color: #f4c57a !important;
        }
        .next-color {
            background-color: #1a85d6;
        }
        .text-black {
            color: #000000;
        }
        .title-color {
            color: #232459 !important;
            font-weight: 500;
        }
        .fs-16 {
            font-size: 1rem;
        }
        .fs-20 {
            font-size: 1.25rem;
        }
        .fs-24 {
            font-size: 1.5rem;
        }
        .fs-18 {
            font-size: 1.125rem;
        }
        .fs-32 {
            font-size: 2rem;
        }
        .fs-14 {
            font-size: 0.875rem;
        }
        .fs-12 {
            font-size: 0.75rem;
        }
        .danger {
            background-color: #d40f78;
            color: white;
        }
        button,
        i,
        mat-icon,
        a,
        li {
            cursor: pointer;
        }
        .progress {
            .mat-select-value-text {
                color: #ffcc00 !important;
            }
        }
        .mat-dropdown-placeholder {
            .mat-select-placeholder {
                padding: 0rem 0.5rem !important;
                font-size: 0.9rem !important;
            }
        }
        .mat-drawer-container {
            /* background-color: #fafafa; */
            color: rgba(0, 0, 0, 0.87);
        }
        .approved {
            background: #07b797 !important;
            font-size: 0.9rem !important;
            width: 4rem;
            height: 1.3rem;
            .mat-select-value-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: white !important;
                padding: 0rem 0rem 0rem 0.5rem;
                font-weight: 500;
            }
        }
        .partially-approved {
            background: #ff6f00 !important;
            font-size: 0.9rem !important;
            width: 4rem;
            height: 1.3rem;
            .mat-select-value-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #ffffff !important;
                padding: 0rem 0rem 0rem 0.5rem;
                font-weight: 500;
            }
        }
        .empty {
            background: #ced5d2 !important;
            font-size: 0.9rem !important;
            width: 4rem;
            height: 1.3rem;
            .mat-select-placeholder {
                background: #ced5d2 !important;
                font-size: 0.8rem !important;
                width: 4rem;
                height: 1.3rem;
            }
        }
        .mat-nav-list {
            .mat-step-text-label {
                overflow: hidden;
                text-transform: none;
                font-weight: 500;
                font-size: 13px !important;
                color: #232459;
            }
            .mat-step-header[aria-selected="true"] {
                background-color: #07b797 !important;
                border-radius: 10px;
                border: 1px solid;
                font-weight: 10px;
                padding: 0px 10px;
                margin: 5px;
                display: flex;
                align-items: center;
                border-radius: 10px !important;
                & div.mat-step-label.mat-step-label-active.mat-step-label-selected {
                    .mat-step-text-label {
                        font-size: 13px !important;
                        overflow: hidden;
                        text-transform: none;
                        font-weight: 500 !important;
                        color: #ffffff;
                    }
                    color: #fff;
                }
            }
            .mat-step-header .mat-step-label.mat-step-label-active {
                color: rgb(0 0 0 / 87%);
            }
            .mat-step-header.cdk-keyboard-focused,
            .mat-step-header.cdk-program-focused,
            .mat-step-header:hover:not([aria-disabled]),
            .mat-step-header {
                color: white !important;
                border-radius: 13.6px !important;
            }
            .mat-vertical-stepper-header {
                // padding: 0.6rem 0.7rem 0.5rem 1.1rem;
                margin: 0.6rem;
                height: 2rem !important;
                padding: 0 10px;
            }
            .mat-vertical-content {
                padding: 0 13.4px 0px 28.7px;
            }
            .mat-vertical-content {
                padding: 0.1rem 0rem 0rem 0.5rem;
            }
            .nav-list {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 14.4px;
                padding: 0rem 0rem;
                li {
                    padding: 0.2rem 0.4rem;
                    margin: 0.6rem 1.6rem;
                    max-width: 16.5rem;
                    border-radius: 0.3rem;
                    color: #060606;
                    font-size: 16px;
                    i {
                        font-weight: 500;
                        font-size: 0.6rem;
                        color: #738199;
                        margin: 0.3rem 0.2rem 0.2rem -0.6rem;
                    }
                }
            }
        }
        .nav-list-columns {
            .mat-step-icon .mat-icon {
                font-size: 17.2px;
                height: 17.9px;
                width: 18.2px;
            }
            html body .mat-nav-list .nav-list li {
                padding: 0.3rem 0rem;
                margin: 0.6rem 2.3rem !important;
                max-width: 12.3rem;
                border-radius: 0.3rem;
                color: #738199;
            }
        }
        .overview-ststus {
            .mat-select-placeholder {
                color: white;
                padding: 0.5rem 0.6rem;
                font-size: 0.9rem;
            }
        }
        .empty-status {
            background: #4f6777 !important;
            font-size: 0.9rem !important;
            width: 4rem;
            height: 1.3rem;
            .mat-select-placeholder {
                font-size: 0.8rem !important;
                width: 4rem;
                height: 1.3rem;
                color: white;
                padding: 0.1rem 0.4rem;
            }
        }
        .mat-select-arrow {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid;
            margin: 0 4px;
            color: white;
        }
        .progress {
            width: 6rem;
            font-size: 0.9rem !important;
            background: #ff6104 !important;
            height: 1.3rem;
            .mat-select-value-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #ffffff !important;
            }
        }
        .under-review {
            width: 6rem;
            font-size: 0.9rem !important;
            background: #ff6104 !important;
            height: 1.3rem;
            padding: 0.3rem 0.3rem 1.5rem 0.5rem !important;
            .mat-select-value-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: white !important;
                font-weight: 500;
            }
        }
        .status-rejected {
            background: #fae9ee !important;
            font-size: 0.9rem !important;
            min-width: 6.9875rem !important;
            height: 1.3rem;
            padding: 0.3rem 0.3rem 1.5rem 0.5rem !important;
            .mat-select-value {
                font-weight: 500;
                display: table-cell;
                max-width: 0;
                width: 100%;
                overflow: hidden;
                text-overflow: none;
                white-space: nowrap;
            }
            .mat-select-value-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #d40f78 !important;
                padding: 0rem 0rem 0rem 0.5rem;
            }
        }
        .status-approved {
            background: #e3fff1 !important;
            font-size: 0.9rem !important;
            width: 6.7rem;
            height: 1.3rem;
            padding: 0.3rem 0.3rem 1.5rem 0.5rem !important;
            .mat-select-value-text {
                font-weight: 500;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #07b797 !important;
                padding: 0rem 0rem 0rem 0.5rem;
            }
        }
        .approve {
            background: #e3fff1 !important;
            font-size: 0.9rem !important;
            width: 4rem;
            height: 1.3rem;
            padding: 0.3rem 0.3rem 1.5rem 0.5rem !important;
            .mat-select-value-text {
                font-weight: 500;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #07b797 !important;
                padding: 0rem 0rem 0rem 0.5rem;
            }
        }
        .contract-signed {
            background: #1a85d6 !important;
            font-size: 0.9rem !important;
            min-width: 6.9875rem !important;
            height: 1.3rem;
            .mat-select-value-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: white !important;
                padding: 0rem 0rem 0rem 0.5rem;
                font-weight: 500;
            }
        }
        .rejected {
            background: #d40f0f !important;
            font-size: 0.9rem !important;
            min-width: 6.9875rem !important;
            height: 1.3rem;
            .mat-select-value {
                font-weight: 500;
                display: table-cell;
                max-width: 0;
                width: 100%;
                overflow: hidden;
                text-overflow: none;
                white-space: nowrap;
            }
            .mat-select-value-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: white !important;
                padding: 0rem 0rem 0rem 0.5rem;
                font-weight: 500;
            }
        }
        .datepicker-mat {
            width: 9.2rem !important;
            .mat-form-field-appearance-outline .mat-form-field-infix {
                padding: 0.1rem 0rem 0.5rem 0rem !important;
            }
        }
        .date-selector {
            .mat-form-field-appearance-outline .mat-form-field-flex {
                padding: 0;
                margin-top: -16.7px;
                position: relative;
                width: 8.5rem;
            }
            .mat-form-field-prefix .mat-icon-button {
                height: 1.5em;
                width: 1.5em;
                color: #706fd3;
            }
            .mat-form-field-appearance-outline .mat-form-field-infix {
                padding: 0rem 0rem 0rem 0rem !important;
            }
            .mat-form-field {
                font-size: inherit;
                font-weight: 400;
                line-height: 0.9;
                letter-spacing: normal;
            }
        }
        .date-picker-mat {
            padding: 0rem 0.3rem 0rem 0.2rem !important;
            padding: 0rem 0.1rem 0.4rem 0.2rem;
            .mat-datepicker-toggle {
                color: rgba(0, 0, 0, 0.54) !important;
                color: #706fd3b8 !important;
                height: 2.7rem !important;
            }
            .text-grey {
                width: 5.9rem;
                height: 1.7rem;
                background: white;
                border-radius: 0.3rem;
                padding: 0rem 0rem 0rem 0.3rem;
            }
        }
        .titlecase-1 {
            background: #e389a3 !important;
            color: #ffffff !important;
        }
        .titlecase-2 {
            background: #59b898 !important;
            color: #ffffff !important;
        }
        .titlecase-3 {
            background: #8943d6 !important;
            color: #ffffff !important;
        }
        .titlecase-4 {
            background: #f0ad42 !important;
            color: #ffffff !important;
        }
        .titlecase-5 {
            background: #65a0db !important;
            color: #ffffff !important;
        }
        .titlecase-6 {
            background: #ab266b !important;
            color: #ffffff !important;
        }
        .titlecase-7 {
            background: #1ac9a8 !important;
            color: #ffffff !important;
        }
        .toe-upload-stepper {
            .mat-step-text-label {
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 1rem;
                font-weight: 500;
                color: #35383a;
            }
            .mat-step-icon-selected {
                background: #59b898 !important;
                color: white;
            }
            .mat-step-icon-state-edit {
                background: #738199;
                color: white;
            }
            .mat-step-icon-state-edit {
                background: #59b898 !important;
                color: white;
            }
        }
        .avatar-content {
            color: #ffffff;
            background-color: #4385d6;
        }
        .overview-screen-datepicker {
            margin: 0rem 2rem;
            max-width: 9.6rem;
            .mat-form-field-appearance-outline .mat-form-field-infix {
                padding: 0.7rem 0rem 0.5rem 0.3rem !important;
            }
            .mat-form-field {
                font-size: inherit;
                font-weight: 400;
                line-height: 0.825;
                font-family: Roboto, "Helvetica Neue", sans-serif;
                letter-spacing: normal;
            }
            .mat-form-field-appearance-outline .mat-form-field-infix {
                padding: 0rem 0rem 0.6rem 0rem !important;
                max-width: 4.6rem;
                min-width: 4.6rem;
            }
            .mat-form-field-infix {
                padding: 0.5em 0;
                border-top: 0rem solid transparent;
            }
            .mat-focus-indicator {
                padding: 0rem !important;
                color: #3e45cd;
            }
            .text-grey {
                color: black !important;
            }
            .mat-form-field {
                font-size: inherit;
                font-weight: 400;
                line-height: 0.725;
                padding: 0rem 0rem 0rem 3.5rem !important;
                /* margin: 0rem 1.9rem; */
            }
        }
        .drawer {
            .mat-list-item {
                display: block;
                border: 1px solid #aaa8a86e;
                -webkit-tap-highlight-color: #b3252500;
                width: 25.8rem;
                background: #c1c1c11a;
                min-height: 6.5rem !important;
                padding: 2.2rem 0rem 0rem 0rem !important;
            }
        }
        .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
            /*   color: #000000;
      font-weight: 500; */
        }
        .mat-form-field-label {
            color: #737e92;
            font-size: 1rem !important;
        }
        .mat-tooltip {
            display: flex !important;
            background: #232459 !important;
            font-size: 12px;
            white-space: pre-line !important;
        }
        .mat-form-field-wrapper {
            padding-bottom: 1em; //0.375em;
        }
        .RiskLogform .mat-form-field-wrapper {
            padding-bottom: 0em; //0.375em;
        }
        .mat-form-field-appearance-outline .mat-form-field-outline {
            color: #00000052;
        }
        .bg-lavendar {
            background-color: #003770;
            color: white;
            // min-height: 5.3rem;
            // padding: 0.4rem 0rem !important;
        }
        .label-design {
            font-size: 18px;
            font-weight: 500;
        }
        mat-form-field.mat-form-field-should-float label .label-design {
            font-size: 18px;
        }
        .mat-menu-content:not(:empty) {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
        }
        .menustyle.mat-menu-panel {
            width: 500px !important;
            min-width: unset !important;
            max-width: unset !important;
            overflow-x: hidden !important;
            margin-top: 1.4rem;
        }
         ::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline {
            color: #aaa8a8 !important;
        }
        .mat-stepper-vertical {
            background-color: white;
            border-radius: 1.1rem;
        }
        .textarea-input {
            .mat-form-field-infix {
                padding: 1.3rem 0rem !important;
            }
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        /* Firefox */
        input[type="number"] {
            -moz-appearance: textfield;
        }
        .mat-tooltip {
            white-space: pre-line;
        }
        .test {
            white-space: pre-line !important;
        }
        .mat-form-field-label {
            .mat-label {
                font-size: 1.0375rem !important;
            }
        }
        .default {
            background: #ced5d2 !important;
            font-size: 0.9rem !important;
            width: 4rem;
            height: 1.3rem;
            background: #ced5d2 !important;
            font-size: 0.8rem !important;
            width: 6.6rem;
            height: 1.6rem;
            padding: 0.2rem 0.1rem;
            .mat-select-placeholder {
                font-size: 0.8rem !important;
                width: 4rem;
                height: 1.3rem;
            }
        }
        .rejected-data {
            background: #fae9ee !important;
            font-size: 0.9rem !important;
            width: 4rem;
            height: 1.3rem;
            .mat-select-value-text {
                font-weight: 500;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #d40f78 !important;
                padding: 0rem 0rem 0rem 0.5rem;
            }
        }
        .partially-approved {
            background: #ffb55a5c !important;
            font-size: 0.9rem !important;
            width: 4rem;
            height: 1.3rem;
            .mat-select-value-text {
                font-weight: 500;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #e1a750 !important;
                padding: 0rem 0rem 0rem 0.5rem;
            }
        }
        .assignee-selectbox {
            background: #ffffff;
            border-radius: 0.4rem;
            width: 23% !important;
            margin: 0.7rem 0rem;
            height: 2.4rem;
            padding: 0.4rem 0.3rem;
            border: 1px solid #00000029;
            .mat-select-value-text {
                font-weight: 500;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #000000 !important;
                padding: 0rem 0rem 0rem 0.5rem;
            }
        }
        .bg-red {
            background-color: red;
        }
        .text-blue {
            color: #1a85d6;
        }
        .add-idea {
            .mat-chip.mat-standard-chip {
                background: #d8eeff45;
                color: #003770;
            }
            .mat-select-value {
                color: #ffffff;
                font-weight: 500;
            }
            .tag {
                .mat-standard-chip {
                    color: #07b797 !important;
                    border-radius: 4.3px;
                }
            }
            .okr {
                .mat-standard-chip {
                    color: #232459 !important;
                    background: #eaeaea;
                }
            }
        }
        .mat-select-value {
            color: rgba(0, 0, 0, 0.87);
            font-weight: 500;
        }
        .mat-form-field-label-wrapper {
            // font-weight: 500 !important;
            // letter-spacing: 0.5px !important;
            // line-height: 1rem !important;
        }
        .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
            font-size: 15.8px !important;
            //letter-spacing: 0.6px;
        }
        .rejected-status {
            background: #ed0000 !important;
            font-size: 0.9rem !important;
            min-width: 6.9875rem !important;
            height: 1.3rem;
            color: white !important;
            .mat-select-value {
                color: white !important;
                font-weight: 500;
                padding: 0rem 0rem 0rem 0.4rem;
            }
        }
        .approved-status {
            background: #1ac9a8 !important;
            font-size: 0.9rem !important;
            width: 4rem;
            height: 1.3rem;
            color: white !important;
            .mat-select-value {
                color: white !important;
                font-weight: 500;
                padding: 0rem 0rem 0rem 0.4rem;
            }
        }
        .datepicker-style {
            align-items: center;
            justify-content: center;
            border: 1px solid #d1cfcf;
            width: 8.3rem;
            max-height: 2.2rem;
            padding: 0.6rem 0.4rem 0.8rem 0rem;
            border-radius: 0.5rem;
            .mat-form-field-placeholder {
                color: red !important;
            }
            .mat-datepicker-toggle {
                color: #706fd3;
            }
        }
        .status-text {
            width: 100%;
            float: right;
            justify-content: end;
            display: flex;
            text-align: right;
            padding: 0px !important;
            vertical-align: middle;
            .status-wrap {
                height: 1.3rem;
                padding: 0 0.5rem;
                font-size: 14px;
                text-align: center;
                margin: 0;
                align-items: center;
                display: flex;
                color: white;
            }
            .innovation-status {
                padding: 0 0.5rem;
                font-size: 0.8rem;
                text-align: center;
                margin: 0;
                display: flex;
                min-width: 5.7rem !important;
                align-items: center;
                justify-content: center;
                border-radius: 0.3rem;
                min-height: 1.8rem;
                color: white;
            }
        }
        .created {
            background: #07b797 !important;
            width: 4.6rem;
            float: right;
            font-size: 0.9rem;
            padding: 0rem 0.8rem !important;
            color: white;
        }
        .green {
            background: #07b797 !important;
            width: 4.6rem;
            float: right;
            font-size: 0.9rem;
            padding: 0.1rem 1.1rem !important;
        }
        .red {
            width: 4.5rem;
            float: right;
            font-size: 1rem;
            padding: 0.1rem 0.4rem;
            height: 1.7rem;
            padding: 0.2rem 0.8rem !important;
        }
        .red {
            background: #ed0404 !important;
            color: #ffffff !important;
        }
        .partially-closed {
            background: #ff6f00 !important;
            color: #ffffff !important;
        }
        .hold {
            background: #daa479 !important;
            color: white !important;
            //width: 4.7rem;
            text-align: center !important;
            padding: 0 1.4rem !important;
        }
        .initiated {
            background: #1a85d6 !important;
            color: white !important;
        }
        .empty {
            border-top: 2.3px solid #7598af !important;
            background: #7598af !important;
        }
        .top-border {
            height: 1px;
            width: 85%;
            position: absolute;
            margin-left: 8%;
        }
        .people-initiative {
            color: #07b797;
            font-weight: 500;
            margin: 0rem 0.5rem;
        }
        .process-initiative {
            color: #232459;
            font-weight: 500;
            margin: 0rem 0.5rem;
        }
        .technology-initiative {
            color: #ed0000;
            font-weight: 500;
            margin: 0rem 0.5rem;
        }
        .business-initiative {
            color: #d40f78;
            font-weight: 500;
            margin: 0rem 0.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 8.2rem;
        }
        .red-border {
            background: #dd2c54;
            border-top: 2.3px solid #dd2c54;
        }
        .signed {
            background: #1a85d6 !important;
            border-top: 2.3px solid #1a85d6 !important;
        }
        .initiated-border {
            background: #1a85d6 !important;
            border-top: 2.3px solid #1a85d6 !important;
        }
        .green-border {
            background: #07b797;
            border-top: 2.3px solid #07b797;
        }
        .partially-closed-border {
            background: #ff6f00;
            border-top: 2.3px solid #ff6f00;
        }
        .active-benefits {
            background: #e4ecf1 !important;
            min-width: 18.5%;
            max-width: 18% !important;
            transition: transform 0.2s;
            /* transform: scale(1.1); */
        }
    }
}

.fw-normal {
    font-weight: normal !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-bold {
    font-weight: bold !important;
}

.mt-80 {
    margin-top: 55px;
}

.b-none {
    border: none !important;
}

.breadcrumb-bs li {
    display: inline-block;
    // font-size: 16px;
}

.breadcrumb-bs li a,
.breadcrumb-bs li a:hover {
    cursor: pointer;
}


/* .treeview-container .row-item {
  margin-bottom: 0.3rem;
  flex-wrap: nowrap;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.treeview-container .row-item {
    margin-bottom: 0.3rem;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: 0.4rem;
    .form-check-label {
        width: 14rem;
        justify-content: flex-start !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        display: inline-block !important;
    }
}

.treeview-text {
    padding: 0.3rem 0;
    white-space: nowrap;
    background: #f2f4f7 !important;
    padding: 0.7rem 5rem !important;
    font-weight: 600 !important;
    border-radius: 0.6rem !important;
}

.breadcrumb-bs li {
    &:before {
        font-family: FontAwesome;
        top: 0;
        content: "\f054";
        margin: 0 6px;
        color: #33ccc0;
        font-size: 16px;
    }
    &:first-child:before {
        content: "";
        margin: 0;
    }
}

.example-tooltip {
    word-break: break-word !important;
    white-space: normal !important;
}

.okrsearchInput.mat-form-field.mat-form-field-should-float label {
    margin-bottom: 0px;
    top: 40px;
}

.angular-editor {
    width: 100% !important;
}

// .mat-form-field-appearance-outline .mat-form-field-infix {
//   padding: 0.5em 0 0.5em 0;
//   /* border: 0px; */
// }
//  ::ng-deep div.mat-form-field-outline-start{
//   border-color: 0 !important;
//   border-width: 0px !important;
// }
// ::ng-deep .mat-form-field-outline-gap{
//   border-color: 0 !important;
//   border-width: 0px !important;
// }
// ::ng-deep .mat-form-field-outline-end{
//   border-color: 0 !important;
//   border-width: 0px !important;
// }
.field-adjust mat-form-field.mat-form-field.mat-form-field-appearance-outline>div.mat-form-field-wrapper>div.mat-form-field-flex>div.mat-form-field-infix {
    padding: 0.6em 0px !important;
} //border: 0px;
.field-adjust mat-form-field.mat-form-field.mat-form-field-appearance-outline>div.mat-form-field-wrapper>div.mat-form-field-flex>div.mat-form-field-infix>span.mat-form-field-label-wrapper {
    top: -1.5em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.1em) scale(.75);
    width: 133.33333%;
}

.mw-550 .modal-dialog {
    max-width: 550px !important;
}

.mw-650 .modal-dialog {
    max-width: 850px !important;
    min-width: 450px;
}

.mw-600 .modal-dialog {
    max-width: 600px !important;
    min-width: 550px;
}

.mw-750 .modal-dialog {
    max-width: 750px !important;
    min-width: 450px;
}

.mw-500 .modal-dialog {
    max-width: 500px !important;
}

.mw-100 .modal-dialog {
    max-width: 100% !important;
}

.vendor-tbl mat-form-field.mat-form-field.mat-form-field-appearance-outline>div.mat-form-field-wrapper>div.mat-form-field-flex>div.mat-form-field-infix {
    padding: 0 0 0 8px;
}

.footer-style {
    box-shadow: 0 -5px 7px -5px rgb(0 0 0 / 15%) !important;
}

::-webkit-scrollbar {
    width: 5px !important;
    max-height: 100rem !important;
    scroll-behavior: smooth;
}

::-webkit-scrollbar-thumb {
    background: #c1c1c1 !important;
}

//::ng-deep{
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
    color: #737e92 !important;
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
    color: rgba(0, 0, 0, 1) !important;
}

.mat-select-disabled .mat-select-value {
    color: rgba(0, 0, 0, 1) !important;
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
    color: rgba(0, 0, 0, 1) !important;
}

.min-card-height {
    min-height: 45.1rem !important;
    max-height: 45.1rem !important;
}

//}
#mosForms {
    .mat-form-field-appearance-outline .mat-form-field-infix input[type="text"] {
        padding: 12px 0px 12px 0px !important;
        max-height: 3.3rem !important;
    }
    // .mat-icon {
    //   line-height: 1.523 !important;
    // }
    .mat-form-field-appearance-outline .mat-form-field-wrapper {
        padding-bottom: 0px !important;
    }
    .mat-form-field-appearance-outline .mat-form-field-outline {
        color: #b9b9b9 !important;
        top: 14px !important;
    }
    label.field-invalid {
        color: #f44336 !important;
    }
    .mat-form-field-flex {
        align-items: center !important;
    }
    .mat-form-field-appearance-outline .mat-form-field-flex input[type="text"] {
        padding: 2px 0 0 0 !important;
    }
    html body .mat-form-field-outline-start {
        border-radius: 0.313rem 0 0 0.313rem !important;
    }
    html body .mat-form-field-outline-end {
        border-radius: 0 0.313rem 0.313rem 0 !important;
    }
    mat-form-field.mat-form-field.mat-form-field-appearance-outline>div.mat-form-field-wrapper>div.mat-form-field-flex>div.mat-form-field-infix>span.mat-form-field-label-wrapper {
        top: -1em !important;
    }
}
.download {
    background: #ffffff !important;
    border: 1px solid #c4c4c4 !important;
    color: #000000 !important;
    border-radius: 0.4rem;
    padding: 0rem 1.3rem 0rem 0.7rem !important;
    font-size: 0.9rem !important;
    border-radius: 0.4rem !important;
    max-height: 2.2rem;
    .filter {
      font-size: 1.1rem;
      padding: 0.1rem 0.1rem !important;
      border: none !important;
      margin: 0.5rem 0.2rem 0.4rem 0rem;
      background: none;
    }
  }

  .single-left-pane{
    height: calc(100vh - 200px);
    background: white;
    border-radius: 25px;
    overflow: auto;
  }

  .single-center-pane{
    height: calc(100vh - 200px);
    padding: 25px;
    background: white;
    border-radius: 25px;
    overflow: auto;
    overflow-x: hidden !important;
  }

.single-right-pane{
    height: calc(100vh - 200px);
    padding: 25px;
    background: white;
    border-radius: 25px;
    overflow: auto;
}
.nodots{
    list-style-type: none; 
    padding-left:10px;
}
.comingSoonCenter{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    height: 90%;

    img{
        display: block;
        margin: auto;
        margin-bottom: 20px;
    }
}


@media only screen and (max-width: 1200px) { 
    .single-left-pane{
        height: calc(100vh - 200px);
        background: white;
        border-radius: 25px;
        overflow: auto;
      }
    
      .single-center-pane{
        height: calc(100vh - 200px);
        background: white;
        border-radius: 25px;
        padding:12px;
        overflow: auto;
      }
    
    .single-right-pane{
        height: calc(100vh - 200px);
        background: white;
        border-radius: 25px;
        overflow: auto;
        padding:12px;
    }
    .col-sm-2{
        padding:0 4px !important
    }
    .col-sm-7{
        padding:0 4px !important
    }
    .col-sm-3{
        padding:0 4px !important
    }
    .p-3{
        padding: 4px !important;
    }
}
th{
    position: sticky; /* make the table heads sticky */
    top: 0px; 
    // z-index: 9999 !important;
}

.fixed-table-header{
    max-height: calc(100vh - 300px);
    overflow:auto
}
.sub-th{
    background: #0bb797 !important;
    z-index:0 !important;
}

.right-panel-bi-class{
    height: 300px;
    width:100%;
}
@media (min-width: 1500px) { 
    .report-container {
        height: 500px !important;
        width: 100%;
    }
}
@media (min-width: 1246px) and (max-width:1499px) {
    .report-container {
        height: 400px !important;
        width: 100%;
    }
  }
@media (min-width:600px) and (max-width: 1245px)  {
    .report-container {
        height: 320px !important;
        width: 100%;
    }
    
  }
  .action-tracker .mat-form-field-appearance-outline .mat-form-field-infix{
    padding:0.7rem 0 !important;
    border-top: 0 !important;
  }

  .action-tracker .mat-form-field-wrapper{
    padding-bottom: 0.4rem !important;
  }

  .incident-tracker .mat-form-field-appearance-outline .mat-form-field-infix{
    padding:0.7rem 0 !important;
    border-top: 0 !important;
  }

  .incident-tracker .mat-form-field-wrapper{
    padding-bottom: 0.4rem !important;
  }
  
  
  .keyTerms {
    .mat-form-field-appearance-outline .mat-form-field-infix {
        padding:1rem 0 0.7rem !important;
        border-top: 0 !important;
    }
    // .mat-form-field-wrapper{
    //     padding-bottom: 0.4rem !important;
    // }
    .mat-form-field-infix{
        font-size: 14px !important;
    }
}

.spendtracker{
.mat-form-field-wrapper{
    padding:0 !important;
    margin: 0 !important;
  }
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding:1rem 0 0.7rem !important;
    border-top: 0 !important;
}
.mat-form-field-infix{
    font-size: 14px !important;
}
}
.scorecard{
    .mat-tab-body.mat-tab-body-active{
        overflow-y: none !important;
    }
}

.mat-horizontal-content-container{
    padding: 0 !important;
  }
 
.mat-slider-thumb-label {
    transform: rotate(45deg) !important;
    border-radius: 50% 50% 0 !important;
  }

.mat-slider-thumb {
    // transform: scale(0) !important;
}

.mat-slider-thumb-label-text {
    opacity: 1 !important;
}
.mat-slider-horizontal .mat-slider-track-background{
    height:4px !important;
    border-radius: 0px 8px 8px 0px !important;
}
.mat-slider-horizontal .mat-slider-track-fill{
    height:4px !important;
    border-radius: 8px 0 0 8px !important;
}
.mat-slider-horizontal .mat-slider-track-wrapper {
    height: 4px !important; /* Set the desired height of the track */
    border-radius: 8px !important;
  }
  
  .mat-slider-horizontal .mat-slider-track {
    height: 100% !important; /* Set the height to 100% to fill the track wrapper */
    border-radius: 8px!important; /* Set border-radius to round the edges (optional) */
  }


  .highcharts-label .highcharts-no-data{
    text{
        transform: translate(50,30) !important;
    }
  }